<template>
  <div class="StaffNewsrooms">
    <banded-section
      title="Newsroom"
      tooltip="This page is visible to hypefactors staff only. This is a list of Newsrooms currently blocked from Hype.News due to spamming (posting more than three articles per day). You can unblock a Newsroom by ticking the box next to the name and then clicking “Unblock”."
      :collapsable="false"
    >
      <heading size="3">
        List of blacklisted Newsrooms
      </heading>
      <div v-loading="isFetchingNewsrooms" class="StaffNewsrooms-List">
        <button
          v-if="blacklistedNewsrooms.length"
          :class="{'is-loading': isLoading}"
          :disabled="verified.length === 0"
          class="button is-primary m-b-m"
          @click="bulkVerify"
        >
          Unblock
        </button>
        <table v-if="blacklistedNewsrooms.length" class="table is-fullwidth">
          <thead>
            <tr>
              <th />
              <th>Logo</th>
              <th>Name</th>
              <th>Published posts in last 24h</th>
              <th>Country</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            <staff-newsroom-item
              v-for="newsroom in blacklistedNewsrooms"
              :key="newsroom.id"
              :newsroom="newsroom"
            >
              <input v-model="verified" :value="newsroom.id" type="checkbox">
            </staff-newsroom-item>
          </tbody>
        </table>
        <div v-else>
          No blacklisted newsrooms
        </div>
      </div>
      <div class="StaffNewsrooms-Search m-t-xxl">
        <heading size="3">
          Blacklist Newsroom by name
        </heading>
        <form @submit.prevent="queryNewsrooms">
          <div class="field has-addons">
            <div class="control">
              <input
                v-model="query"
                class="input"
                type="text"
                placeholder="Newsroom name"
              >
            </div>
            <div class="control">
              <button
                :class="{'is-loading': isLoading}"
                :disabled="query.length < 3"
                class="button is-primary m-b-m"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        <ul v-if="noneBlacklisted.length" class="FoundNewsroomsList">
          <li
            v-for="newsroom in noneBlacklisted"
            :key="newsroom.id"
            class="FoundNewsroomsList-Item is-flex is-aligned-justify"
          >
            <a :href="$getUrl(newsroom.slug, 'hypenews', false)" class="" target="_blank">
              {{ newsroom.name }} [{{ $safeGet(newsroom, 'country.data.name') }}]
            </a>
            <button
              :class="{'is-loading': isLoading}"
              class="button is-black m-b-m"
              @click.prevent="markNewsroomAsBlacklisted(newsroom)"
            >
              Mark as Blacklisted
            </button>
          </li>
        </ul>
        <p v-else>
          No Newsrooms found.
        </p>
      </div>
    </banded-section>
  </div>
</template>

<script>
import StaffNewsroomItem from '@/components/staff/StaffNewsroomItem'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'

export default {
  components: {
    StaffNewsroomItem
  },

  data () {
    return {
      query: '',
      verified: [],
      isLoading: false,
      foundNewsrooms: [],
      blacklistedNewsrooms: [],
      isFetchingNewsrooms: false
    }
  },

  computed: {
    /**
     * Returns an array of none blacklisted newsrooms from the searched ones
     * @returns {[]}
     */
    noneBlacklisted () {
      return this.foundNewsrooms.filter(newsroom => {
        return !newsroom.is_blacklisted
      })
    }
  },

  mounted () {
    this.fetchBlacklistedNewsrooms()
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    /**
     * Fetches all the blacklisted newsrooms.
     * @returns {Promise}
     */
    fetchBlacklistedNewsrooms () {
      this.isFetchingNewsrooms = true
      this.cancelToken = this.$api.cancelToken()
      return this.$api.fetchAllWithCursor('/staff/newsrooms?include=country&status=blacklisted', {
        cancelToken: this.cancelToken.token
      })
        .then(response => {
          this.isFetchingNewsrooms = false
          this.blacklistedNewsrooms = response
        })
        .catch(error => {
          if (this.$api.isCancelToken(error)) {
            return
          }
          this.isFetchingNewsrooms = false
          this.$displayRequestError(error)
        })
    },
    /**
     * Bulk verifies all the checked newsrooms
     * @returns {Promise<*>}
     */
    bulkVerify () {
      if (!this.verified.length) return Promise.resolve()
      this.isLoading = true
      const payload = {
        newsrooms: this.verified
      }
      return this.$api.post('/staff/newsrooms/verify', payload)
        .then(response => {
          this.isLoading = false
          this.verified = []
          this.removeVerifiedNewsrooms(payload.newsrooms)
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.error'))
        })
    },
    /**
     * Removes the verified newsrooms from the blacklisted list.
     * @param newsroomIds
     */
    removeVerifiedNewsrooms (newsroomIds) {
      this.blacklistedNewsrooms = this.blacklistedNewsrooms.filter(newsroom => {
        return !newsroomIds.includes(newsroom.id)
      })
    },

    /**
     * Finds the newsrooms by the provided query parameter
     */
    queryNewsrooms () {
      this.isLoading = true
      NewsroomService.findNewsrooms(this.query, {
        include: 'country'
      })
        .then(response => {
          this.isLoading = false
          this.foundNewsrooms = response
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.error'))
        })
    },
    /**
     * Marks a newsroom as blacklisted
     * @param newsroom
     */
    markNewsroomAsBlacklisted (newsroom) {
      this.isLoading = true
      const payload = {
        newsrooms: [newsroom.id]
      }
      this.$api.post('/staff/newsrooms/blacklist', payload)
        .then(response => {
          this.isLoading = false
          this.query = ''
          this.blacklistedNewsrooms.push(newsroom)
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.error'))
        })
    }
  }
}
</script>
