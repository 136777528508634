<template>
  <tr class="StaffNewsroomItem">
    <td>
      <slot />
    </td>
    <td>
      <round-avatar
        :src="newsroom.logo.cropped"
      />
    </td>
    <td>
      <a :href="newsroomLink" target="_blank">{{ newsroom.name }}</a>
    </td>
    <td>
      {{ newsroom.daily_stories_count || 0 }}
    </td>
    <td>
      <div v-if="newsroomCountry">
        <country-flag :country="newsroomCountry.iso_alpha2" />
        {{ newsroomCountry.name }}
      </div>
      <div v-else>
        No Country
      </div>
    </td>
    <td>
      <div :class="[newsroom.is_published ? 'has-text-success':'has-text-danger']">
        {{ newsroom.is_published ? 'Published' : 'Unpublished' }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },
  computed: {
    newsroomCountry () {
      return this.$safeGet(this.newsroom, 'country.data')
    },
    newsroomLink () {
      return this.$getUrl(this.newsroom.slug, 'hypenews', false)
    }
  }
}
</script>
